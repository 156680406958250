import { getField, updateField } from 'vuex-map-fields'
import requests from '../requests'
import { SCORE_URL, CUSTOMER_SURVEY_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  organization_id: null,
  survey_flow: 'auto',
  greetings: {
    closing_message: '',
    survey_title: '',
    survey_closing: '',
  },
  csat: {
    is_active: true,
    channel_integration: {
      enable_all: false,
      channels: [],
    },
    main_question: '',
    answer_options: {
      rating_1: '',
      rating_2: '',
      rating_3: '',
      rating_4: '',
      rating_5: '',
    },
    follow_up_type: '',
    simple_follow_up: '',
    rating_follow_up: {
      rating_1: '',
      rating_2: '',
      rating_3: '',
      rating_4: '',
      rating_5: '',
    },
    scoring_method: '',
  },
  ces: {
    is_active: true,
    channel_integration: {
      enable_all: false,
      channels: [],
    },
    main_question: 'test',
    answer_options: {
      bottom: '',
      top: '',
    },
    follow_up_type: '',
    simple_follow_up: '',
    rating_follow_up: {
      bottom: '',
      neutral: '',
      top: '',
    },
  },
  nps: {
    is_active: true,
    channel_integration: {
      enable_all: false,
      channels: [],
    },
    main_question: '',
    answer_options: {
      low: '',
      high: '',
    },
    follow_up_type: '',
    simple_follow_up: '',
    rating_follow_up: {
      promotor: '',
      passive: '',
      detractor: '',
    },
  },
  tempCustomerSurvey: {
    greetings: null,
    csat: null,
    ces: null,
    nps: null,
  },
  surveyTypesCollapse: {
    visibleCsat: false,
    visibleCes: false,
    visibleNps: false,
  },
  csatChannelLists: [],
  cesChannelLists: [],
  npsChannelLists: [],
  csatSettings: {
    id: '',
    is_active: false,
    main_question: '',
    rating_1: '',
    rating_2: '',
    rating_3: '',
    rating_4: '',
    rating_5: '',
    set_follow_up: 0,
    follow_up_2: '',
    follow_up_rating_1: '',
    follow_up_rating_2: '',
    follow_up_rating_3: '',
    follow_up_rating_4: '',
    follow_up_rating_5: '',
    header: '',
    thanks_message: '',
    message_callback_csat: '',
  },
  npsSettings: {
    id: '',
    is_active: false,
    main_question: '',
    follow_up_set: 0,
    follow_up_2: '',
    header: '',
    thanks_message: '',
    customer_message: '',
    scale_answer_low: '',
    scale_answer_high: '',
    follow_up_detractor: '',
    follow_up_passive: '',
    follow_up_promoter: '',
  },
  cesSettings: {
    id: '',
    is_active: false,
    main_question: '',
    set_follow_up: 0,
    follow_up_2: '',
    header: '',
    message_callback_ces: '',
    thanks_message: '',
    scale_answer_bottom: '',
    scale_answer_top: '',
    follow_up_bottom: '',
    follow_up_neutral: '',
    follow_up_top: '',
  },
  messageSettings: {
    header: '',
    thanks_message: '',
    customer_message: '',
  },
})

export const getters = {
  getField,
}

export const actions = {
  getCustomerSurveySettings({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          CUSTOMER_SURVEY_URL,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_CUSTOMER_SURVEY_SETTINGS', res.data)
            commit('UPDATE_TEMP_CUSTOMER_SURVEY_SETTINGS', res.data)
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              {
                title: err?.error?.messages?.[0],
                variant: 'error',
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  updateCustomerSurvey({ commit, state }, payload) {
    const parameter = {
      survey_flow: state.survey_flow,
      greetings: state.greetings,
      csat: state.csat,
      ces: state.ces,
      nps: state.nps,
    }
    if (!state.csat.channel_integration?.ids?.length) {
      commit('UPDATE_CHANNELS_INT_ID', { type: 'csat' })
    }
    if (!state.ces.channel_integration?.ids?.length) {
      commit('UPDATE_CHANNELS_INT_ID', { type: 'ces' })
    }
    if (!state.nps.channel_integration?.ids?.length) {
      commit('UPDATE_CHANNELS_INT_ID', { type: 'nps' })
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${CUSTOMER_SURVEY_URL}`, parameter, {
          Authorization: this.$auth.getToken,
        })
        .subscribe(
          (res) => {
            commit('REMOVE_CUSTOMER_SURVEY_CHANNELS_IDS')
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getCSATSettings({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${SCORE_URL.csat}/setting`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (res.data) {
              commit('UPDATE_CSAT_SETTINGS', res.data)
            }
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message:
                  'Failed to retrieve CSAT settings. Please try again later',
              },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  createCSAT({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .postService(`${SCORE_URL.csat}/setting`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Successfully saved!' },
              { root: true }
            )
            commit('UPDATE_CSAT_SETTINGS', res.data)
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message: 'Failed to save CSAT settings. Please try again later',
              },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  updateCSAT({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .putService(`${SCORE_URL.csat}/setting`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (!params.disableToast)
              commit(
                'layouts/DISPLAY_TOAST',
                { message: 'Successfully saved!' },
                { root: true }
              )
            commit('UPDATE_CSAT_SETTINGS', res.data)
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message: 'Failed to save CSAT settings. Please try again later',
              },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  getNPSSettings({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${SCORE_URL.nps}/setting`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (res.data) {
              commit('UPDATE_NPS_SETTINGS', res.data)
            } else {
              commit('UPDATE_DEFAULT_NPS_SETTINGS')
            }
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message:
                  'Failed to retrieve NPS settings. Please try again later',
              },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  createNPS({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .postService(`${SCORE_URL.nps}/setting`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Successfully saved!' },
              { root: true }
            )
            commit('UPDATE_NPS_SETTINGS', res.data)
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message: 'Failed to save NPS settings. Please try again later',
              },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  updateNPS({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .putService(`${SCORE_URL.nps}/setting`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (!params.disableToast)
              commit(
                'layouts/DISPLAY_TOAST',
                { message: 'Successfully saved!' },
                { root: true }
              )
            commit('UPDATE_NPS_SETTINGS', res.data)
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message: 'Failed to save NPS settings. Please try again later',
              },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  getCESSettings({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${SCORE_URL.ces}/setting`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (res.data) {
              commit('UPDATE_CES_SETTINGS', res.data)
            }
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message:
                  'Failed to retrieve CES settings. Please try again later',
              },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  createCES({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .postService(`${SCORE_URL.ces}/setting`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Successfully saved!' },
              { root: true }
            )
            commit('UPDATE_CES_SETTINGS', res.data)
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message: 'Failed to save CES settings. Please try again later',
              },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  updateCES({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .putService(`${SCORE_URL.ces}/setting`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (!params.disableToast)
              commit(
                'layouts/DISPLAY_TOAST',
                { message: 'Successfully saved!' },
                { root: true }
              )
            commit('UPDATE_CES_SETTINGS', res.data)
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message: 'Failed to save CES settings. Please try again later',
              },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  getCustomerSurveyChannel({ commit, state }, payload) {
    const surveyType = payload.type
    const query = {
      query: payload.query,
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${CUSTOMER_SURVEY_URL}/${surveyType}/channels`, query, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              `UPDATE_SURVEY_CHANNELS_${surveyType.toUpperCase()}`,
              res?.data
            )
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  searchCustomerSurveyChannel({ commit, state }, payload) {
    const surveyType = payload.type
    const query = {
      query: payload.query,
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${CUSTOMER_SURVEY_URL}/${surveyType}/channels`, query, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_CSAT_SETTINGS(state, payload) {
    state.csatSettings = {
      id: payload.id,
      is_active: payload.is_active,
      main_question: payload.main_question,
      rating_1: payload.answer_options.rating_1,
      rating_2: payload.answer_options.rating_2,
      rating_3: payload.answer_options.rating_3,
      rating_4: payload.answer_options.rating_4,
      rating_5: payload.answer_options.rating_5,
      set_follow_up: payload.set_follow_up,
      follow_up_2: payload.follow_up_2,
      follow_up_rating_1: payload.follow_up_3.follow_up_rating_1,
      follow_up_rating_2: payload.follow_up_3.follow_up_rating_2,
      follow_up_rating_3: payload.follow_up_3.follow_up_rating_3,
      follow_up_rating_4: payload.follow_up_3.follow_up_rating_4,
      follow_up_rating_5: payload.follow_up_3.follow_up_rating_5,
      header: payload.header,
      thanks_message: payload.thanks_message,
      message_callback_csat: payload.message_callback_csat,
    }
  },
  UPDATE_NPS_SETTINGS(state, payload) {
    state.npsSettings = {
      ...payload,
      scale_answer_low: payload.scale_answer.low,
      scale_answer_high: payload.scale_answer.high,
      follow_up_detractor: payload.follow_up_3.follow_up_detractor,
      follow_up_passive: payload.follow_up_3.follow_up_passive,
      follow_up_promoter: payload.follow_up_3.follow_up_promoter,
    }
  },
  UPDATE_DEFAULT_NPS_SETTINGS(state) {
    state.npsSettings.main_question =
      'Seberapa besar kemungkinan Anda akan merekomendasikan produk dan layanan kami kepada teman? Pilihlah satu angka mulai dari 0 sampai 10'
    state.npsSettings.follow_up_set = 0
    state.npsSettings.scale_answer_low = 'Sama sekali tidak mungkin'
    state.npsSettings.scale_answer_high = 'Sangat mungkin'
  },
  UPDATE_CES_SETTINGS(state, payload) {
    state.cesSettings = {
      ...payload,
      scale_answer_bottom: payload.scale_answer.bottom_scale,
      scale_answer_top: payload.scale_answer.top_scale,
      follow_up_bottom: payload.follow_up_3.follow_up_bottom,
      follow_up_neutral: payload.follow_up_3.follow_up_neutral,
      follow_up_top: payload.follow_up_3.follow_up_top,
    }
  },
  UPDATE_MESSAGE_SETTINGS(state, payload) {
    state.messageSettings = payload
  },
  REMOVE_CUSTOMER_SURVEY_CHANNELS_IDS(state, payload) {
    state.csat.channel_integration.ids = []
    state.ces.channel_integration.ids = []
    state.nps.channel_integration.ids = []
  },
  UPDATE_CUSTOMER_SURVEY_SETTINGS(state, payload) {
    state.organization_id = payload?.organization_id
    state.survey_flow = payload?.survey_flow
    state.csat = { ...payload?.csat }
    state.ces = { ...payload?.ces }
    state.nps = { ...payload?.nps }
    state.greetings = { ...payload?.greetings }
  },
  UPDATE_TEMP_CUSTOMER_SURVEY_SETTINGS(state, payload) {
    state.tempCustomerSurvey.greetings = { ...payload?.greetings }
    state.tempCustomerSurvey.csat = { ...payload?.csat }
    state.tempCustomerSurvey.ces = { ...payload?.ces }
    state.tempCustomerSurvey.nps = { ...payload?.nps }
  },
  UPDATE_MAIN_QUESTION_CSAT(state, payload) {
    state.csat.main_question = payload
  },
  UPDATE_MAIN_QUESTION(state, payload) {
    state[payload.type].main_question = payload.main_question
  },
  UPDATE_TOGGLE_IS_ACTIVE(state, payload) {
    state[payload.type].is_active = payload?.is_active
  },
  UPDATE_CSAT_ANSWER_OPTIONS(state, payload) {
    state.csat.answer_options.rating_1 = payload?.rating1
    state.csat.answer_options.rating_2 = payload?.rating2
    state.csat.answer_options.rating_3 = payload?.rating3
    state.csat.answer_options.rating_4 = payload?.rating4
    state.csat.answer_options.rating_5 = payload?.rating5
  },
  UPDATE_CES_ANSWER_OPTIONS(state, payload) {
    state.ces.answer_options.top = payload?.top
    state.ces.answer_options.bottom = payload?.bottom
  },
  UPDATE_SURVEY_TYPES_COLLAPSE(state, payload) {
    state.surveyTypesCollapse.visibleCsat = payload?.visibleCsat
    state.surveyTypesCollapse.visibleCes = payload?.visibleCes
    state.surveyTypesCollapse.visibleNps = payload?.visibleNps
  },
  UPDATE_SURVEY_CHANNELS_CSAT(state, payload) {
    if (!payload) {
      return
    }
    state.csatChannelLists = payload
    state.csatChannelLists.forEach((chGroup) => {
      chGroup.channels.forEach((ch) => {
        ch.enabled = false
        state.csat.channel_integration.channels.forEach((channel) => {
          if (ch.id === channel.id) {
            ch.enabled = true
          }
        })
      })
    })
    // check and update checkbox parent
    state.csatChannelLists.forEach((chGroup) => {
      const isEnableAllGroup = chGroup.channels.every(
        (channel) => channel.enabled
      )
      const isIndeterminateGroup = chGroup.channels.some(
        (channel) => channel.enabled
      )
      const isDisabledAllGroup = chGroup.channels.every(
        (channel) => !channel.enabled
      )
      if (isEnableAllGroup) chGroup.status = 'all'
      else if (isDisabledAllGroup) chGroup.status = 'disabled'
      else if (isIndeterminateGroup) chGroup.status = 'specific'
    })
  },
  UPDATE_SURVEY_CHANNELS_CES(state, payload) {
    if (!payload) {
      return
    }
    state.cesChannelLists = payload
    state.cesChannelLists.forEach((chGroup) => {
      chGroup.channels.forEach((ch) => {
        ch.enabled = false
        state.ces.channel_integration.channels.forEach((channel) => {
          if (ch.id === channel.id) {
            ch.enabled = true
          }
        })
      })
    })
    // check and update checkbox parent
    state.cesChannelLists.forEach((chGroup) => {
      const isEnableAllGroup = chGroup.channels.every(
        (channel) => channel.enabled
      )
      const isIndeterminateGroup = chGroup.channels.some(
        (channel) => channel.enabled
      )
      const isDisabledAllGroup = chGroup.channels.every(
        (channel) => !channel.enabled
      )
      if (isEnableAllGroup) chGroup.status = 'all'
      else if (isDisabledAllGroup) chGroup.status = 'disabled'
      else if (isIndeterminateGroup) chGroup.status = 'specific'
    })
  },
  UPDATE_SURVEY_CHANNELS_NPS(state, payload) {
    if (!payload) {
      return
    }
    state.npsChannelLists = payload
    state.npsChannelLists.forEach((chGroup) => {
      chGroup.channels.forEach((ch) => {
        ch.enabled = false
        state.nps.channel_integration.channels.forEach((channel) => {
          if (ch.id === channel.id) {
            ch.enabled = true
          }
        })
      })
    })
    // check and update checkbox parent
    state.npsChannelLists.forEach((chGroup) => {
      const isEnableAllGroup = chGroup.channels.every(
        (channel) => channel.enabled
      )
      const isIndeterminateGroup = chGroup.channels.some(
        (channel) => channel.enabled
      )
      const isDisabledAllGroup = chGroup.channels.every(
        (channel) => !channel.enabled
      )
      if (isEnableAllGroup) chGroup.status = 'all'
      else if (isDisabledAllGroup) chGroup.status = 'disabled'
      else if (isIndeterminateGroup) chGroup.status = 'specific'
    })
  },
  UPDATE_SIMPLE_FOLLOW_UP(state, payload) {
    state[payload.type].simple_follow_up = payload.simple_follow_up
  },
  UPDATE_RATING_FOLLOW_UP(state, payload) {
    state[payload.type].rating_follow_up = { ...payload.rating_follow_up }
  },
  UPDATE_ANSWER_OPTIONS(state, payload) {
    state[payload.type].answer_options = { ...payload.answer_options }
  },
  UPDATE_FOLLOW_UP_TYPE(state, payload) {
    state[payload.type].follow_up_type = payload.follow_up_type
  },
  UPDATE_ENABLE_ALL_CHANNEL(state, payload) {
    state[payload.type].channel_integration.enable_all = payload?.enable_all
  },
  UPDATE_ENABLE_ALL_CHANNEL_DRAWER(state, payload) {
    state[payload.type].channel_integration.enable_all = payload?.enable_all

    if (payload.type === 'csat') {
      state.csatChannelLists.forEach((list) => {
        list.channels.forEach((acc) => {
          acc.enabled = payload?.enable_all
        })
        list.status = payload?.enable_all ? 'all' : 'disabled'
      })
    }
    if (payload.type === 'ces') {
      state.cesChannelLists.forEach((list) => {
        list.channels.forEach((acc) => {
          acc.enabled = payload?.enable_all
        })
        list.status = payload?.enable_all ? 'all' : 'disabled'
      })
    }
    if (payload.type === 'nps') {
      state.npsChannelLists.forEach((list) => {
        list.channels.forEach((acc) => {
          acc.enabled = payload?.enable_all
        })
        list.status = payload?.enable_all ? 'all' : 'disabled'
      })
    }
  },
  UPDATE_CSAT_CHANNEL_LIST_STATUS(state, payload) {
    let childStatus = false
    if (payload.status === 'all') {
      childStatus = true
    } else {
      childStatus = false
    }
    const index = payload?.index
    // update channels group child status
    state.csatChannelLists[index].status = payload?.status
    for (let i = 0; i < state.csatChannelLists[index].channels.length; i++) {
      const el = state.csatChannelLists[index].channels[i]
      el.enabled = childStatus
    }

    const isGroupChannelEnabledAll = state.csatChannelLists.every(
      (channel) => channel.status === 'all'
    )
    const isGroupChannelDisableddAll = state.csatChannelLists.every(
      (channel) => channel.status === 'disabled'
    )
    if (isGroupChannelEnabledAll) {
      state.csat.channel_integration.enable_all = true
    }
    if (isGroupChannelDisableddAll) {
      state.csat.channel_integration.enable_all = false
    }
  },
  UPDATE_CES_CHANNEL_LIST_STATUS(state, payload) {
    let childStatus = false
    if (payload.status === 'all') {
      childStatus = true
    } else {
      childStatus = false
    }
    const index = payload?.index
    // update channels group child status
    state.cesChannelLists[index].status = payload?.status
    for (let i = 0; i < state.cesChannelLists[index].channels.length; i++) {
      const el = state.cesChannelLists[index].channels[i]
      el.enabled = childStatus
    }

    const isGroupChannelEnabledAll = state.cesChannelLists.every(
      (channel) => channel.status === 'all'
    )
    const isGroupChannelDisableddAll = state.cesChannelLists.every(
      (channel) => channel.status === 'disabled'
    )
    if (isGroupChannelEnabledAll) {
      state.ces.channel_integration.enable_all = true
    }
    if (isGroupChannelDisableddAll) {
      state.ces.channel_integration.enable_all = false
    }
  },
  UPDATE_NPS_CHANNEL_LIST_STATUS(state, payload) {
    let childStatus = false
    if (payload.status === 'all') {
      childStatus = true
    } else {
      childStatus = false
    }
    const index = payload?.index
    // update channels group child status
    state.npsChannelLists[index].status = payload?.status
    for (let i = 0; i < state.npsChannelLists[index].channels.length; i++) {
      const el = state.npsChannelLists[index].channels[i]
      el.enabled = childStatus
    }

    const isGroupChannelEnabledAll = state.npsChannelLists.every(
      (channel) => channel.status === 'all'
    )
    const isGroupChannelDisableddAll = state.npsChannelLists.every(
      (channel) => channel.status === 'disabled'
    )
    if (isGroupChannelEnabledAll) {
      state.nps.channel_integration.enable_all = true
    }
    if (isGroupChannelDisableddAll) {
      state.nps.channel_integration.enable_all = false
    }
  },
  UPDATE_CSAT_CHANNEL_LIST_STATUS_SEARCH(state, payload) {
    let childStatus = false
    if (payload.status === 'all') {
      childStatus = true
    } else {
      childStatus = false
    }

    const findGroup = state.csatChannelLists.find((list) => {
      return list.channel_group === payload.channelGroup.channel_group
    })

    // update channels group status
    if (findGroup.channels.length === payload?.channelGroup.channels.length) {
      findGroup.status = payload?.status
    } else {
      findGroup.status = 'specific'
    }

    // update channels group child status
    payload.channelGroup.channels.forEach((ch) => {
      const chId = ch.id
      findGroup.channels.forEach((fCh) => {
        if (chId === fCh.id) {
          fCh.enabled = childStatus
        }
      })
    })
  },
  UPDATE_CES_CHANNEL_LIST_STATUS_SEARCH(state, payload) {
    let childStatus = false
    if (payload.status === 'all') {
      childStatus = true
    } else {
      childStatus = false
    }

    const findGroup = state.cesChannelLists.find((list) => {
      return list.channel_group === payload.channelGroup.channel_group
    })

    // update channels group status
    if (findGroup.channels.length === payload?.channelGroup.channels.length) {
      findGroup.status = payload?.status
    } else {
      findGroup.status = 'specific'
    }

    // update channels group child status
    payload.channelGroup.channels.forEach((ch) => {
      const chId = ch.id
      findGroup.channels.forEach((fCh) => {
        if (chId === fCh.id) {
          fCh.enabled = childStatus
        }
      })
    })
  },
  UPDATE_NPS_CHANNEL_LIST_STATUS_SEARCH(state, payload) {
    let childStatus = false
    if (payload.status === 'all') {
      childStatus = true
    } else {
      childStatus = false
    }

    const findGroup = state.npsChannelLists.find((list) => {
      return list.channel_group === payload.channelGroup.channel_group
    })

    // update channels group status
    if (findGroup.channels.length === payload?.channelGroup.channels.length) {
      findGroup.status = payload?.status
    } else {
      findGroup.status = 'specific'
    }

    // update channels group child status
    payload.channelGroup.channels.forEach((ch) => {
      const chId = ch.id
      findGroup.channels.forEach((fCh) => {
        if (chId === fCh.id) {
          fCh.enabled = childStatus
        }
      })
    })
  },
  UPDATE_CSAT_CHANNEL_ACOUNT_STATUS(state, payload) {
    const key = payload?.key
    const i = payload?.i
    const status = payload?.status
    state.csatChannelLists[key].channels[i].enabled = status

    const isEnableAll = state.csatChannelLists[key].channels.every(
      (account) => account.enabled
    )
    const isIndeterminate = state.csatChannelLists[key].channels.some(
      (account) => account.enabled
    )
    const isDisabledAll = state.csatChannelLists[key].channels.every(
      (account) => !account.enabled
    )

    let groupChannelStatus = ''
    if (isEnableAll) groupChannelStatus = 'all'
    if (isDisabledAll) groupChannelStatus = 'disabled'
    if (isIndeterminate) groupChannelStatus = 'specific'
    if (isEnableAll && isIndeterminate) groupChannelStatus = 'all'
    state.csatChannelLists[key].status = groupChannelStatus
  },
  UPDATE_CES_CHANNEL_ACOUNT_STATUS(state, payload) {
    const key = payload?.key
    const i = payload?.i
    const status = payload?.status
    state.cesChannelLists[key].channels[i].enabled = status

    const isEnableAll = state.cesChannelLists[key].channels.every(
      (account) => account.enabled
    )
    const isIndeterminate = state.cesChannelLists[key].channels.some(
      (account) => account.enabled
    )
    const isDisabledAll = state.cesChannelLists[key].channels.every(
      (account) => !account.enabled
    )

    let groupChannelStatus = ''
    if (isEnableAll) groupChannelStatus = 'all'
    if (isDisabledAll) groupChannelStatus = 'disabled'
    if (isIndeterminate) groupChannelStatus = 'specific'
    if (isEnableAll && isIndeterminate) groupChannelStatus = 'all'
    state.cesChannelLists[key].status = groupChannelStatus
  },
  UPDATE_NPS_CHANNEL_ACOUNT_STATUS(state, payload) {
    const key = payload?.key
    const i = payload?.i
    const status = payload?.status
    state.npsChannelLists[key].channels[i].enabled = status

    const isEnableAll = state.npsChannelLists[key].channels.every(
      (account) => account.enabled
    )
    const isIndeterminate = state.npsChannelLists[key].channels.some(
      (account) => account.enabled
    )
    const isDisabledAll = state.npsChannelLists[key].channels.every(
      (account) => !account.enabled
    )

    let groupChannelStatus = ''
    if (isEnableAll) groupChannelStatus = 'all'
    if (isDisabledAll) groupChannelStatus = 'disabled'
    if (isIndeterminate) groupChannelStatus = 'specific'
    if (isEnableAll && isIndeterminate) groupChannelStatus = 'all'
    state.npsChannelLists[key].status = groupChannelStatus
  },
  UPDATE_CSAT_CHANNEL_ACOUNT_STATUS_SEARCH(state, payload) {
    const status = payload?.status
    const channelGroup = payload.channelGroup

    const findGroup = state.csatChannelLists.find((list) => {
      return list.channel_group === channelGroup.channel_group
    })

    findGroup.channels.forEach((ch) => {
      if (ch.id === payload?.channel.id) {
        ch.enabled = status
      }
    })

    const isEnableAll = findGroup.channels.every((account) => account.enabled)
    const isIndeterminate = findGroup.channels.some(
      (account) => account.enabled
    )
    const isDisabledAll = findGroup.channels.every(
      (account) => !account.enabled
    )

    let groupChannelStatus = ''
    if (isEnableAll) groupChannelStatus = 'all'
    if (isDisabledAll) groupChannelStatus = 'disabled'
    if (isIndeterminate) groupChannelStatus = 'specific'
    if (isEnableAll && isIndeterminate) groupChannelStatus = 'all'
    findGroup.status = groupChannelStatus
  },
  UPDATE_CES_CHANNEL_ACOUNT_STATUS_SEARCH(state, payload) {
    const status = payload?.status
    const channelGroup = payload.channelGroup

    const findGroup = state.cesChannelLists.find((list) => {
      return list.channel_group === channelGroup.channel_group
    })

    findGroup.channels.forEach((ch) => {
      if (ch.id === payload?.channel.id) {
        ch.enabled = status
      }
    })

    const isEnableAll = findGroup.channels.every((account) => account.enabled)
    const isIndeterminate = findGroup.channels.some(
      (account) => account.enabled
    )
    const isDisabledAll = findGroup.channels.every(
      (account) => !account.enabled
    )

    let groupChannelStatus = ''
    if (isEnableAll) groupChannelStatus = 'all'
    if (isDisabledAll) groupChannelStatus = 'disabled'
    if (isIndeterminate) groupChannelStatus = 'specific'
    if (isEnableAll && isIndeterminate) groupChannelStatus = 'all'
    findGroup.status = groupChannelStatus
  },
  UPDATE_NPS_CHANNEL_ACOUNT_STATUS_SEARCH(state, payload) {
    const status = payload?.status
    const channelGroup = payload.channelGroup

    const findGroup = state.npsChannelLists.find((list) => {
      return list.channel_group === channelGroup.channel_group
    })

    findGroup.channels.forEach((ch) => {
      if (ch.id === payload?.channel.id) {
        ch.enabled = status
      }
    })

    const isEnableAll = findGroup.channels.every((account) => account.enabled)
    const isIndeterminate = findGroup.channels.some(
      (account) => account.enabled
    )
    const isDisabledAll = findGroup.channels.every(
      (account) => !account.enabled
    )

    let groupChannelStatus = ''
    if (isEnableAll) groupChannelStatus = 'all'
    if (isDisabledAll) groupChannelStatus = 'disabled'
    if (isIndeterminate) groupChannelStatus = 'specific'
    if (isEnableAll && isIndeterminate) groupChannelStatus = 'all'
    findGroup.status = groupChannelStatus
  },
  SAVE_CSAT_CHANNEL_DRAWER(state, payload) {
    const csatChannels = []
    state.csatChannelLists.map((groupChannel) => {
      groupChannel.channels.forEach((channel) => {
        if (channel.enabled) {
          csatChannels.push(channel)
        }
      })
      return (state.csat.channel_integration.channels = csatChannels)
    })

    const isAllEnabled = state.csatChannelLists.every(
      (channel) => channel.status === 'all'
    )
    const isAllDisabled = state.csatChannelLists.every(
      (channel) => channel.status === 'disabled'
    )
    if (isAllEnabled) state.csat.channel_integration.enable_all = true
    else if (isAllDisabled) state.csat.channel_integration.enable_all = true
    else state.csat.channel_integration.enable_all = false
  },
  SAVE_CES_CHANNEL_DRAWER(state, payload) {
    const cesChannels = []
    state.cesChannelLists.map((groupChannel) => {
      groupChannel.channels.forEach((channel) => {
        if (channel.enabled) {
          cesChannels.push(channel)
        }
      })
      return (state.ces.channel_integration.channels = cesChannels)
    })

    const isAllEnabled = state.cesChannelLists.every(
      (channel) => channel.status === 'all'
    )
    const isAllDisabled = state.cesChannelLists.every(
      (channel) => channel.status === 'disabled'
    )
    if (isAllEnabled) state.ces.channel_integration.enable_all = true
    else if (isAllDisabled) state.ces.channel_integration.enable_all = true
    else state.ces.channel_integration.enable_all = false
  },
  SAVE_NPS_CHANNEL_DRAWER(state, payload) {
    const npsChannels = []
    state.npsChannelLists.map((groupChannel) => {
      groupChannel.channels.forEach((channel) => {
        if (channel.enabled) {
          npsChannels.push(channel)
        }
      })
      return (state.nps.channel_integration.channels = npsChannels)
    })

    const isAllEnabled = state.npsChannelLists.every(
      (channel) => channel.status === 'all'
    )
    const isAllDisabled = state.npsChannelLists.every(
      (channel) => channel.status === 'disabled'
    )
    if (isAllEnabled) state.nps.channel_integration.enable_all = true
    else if (isAllDisabled) state.nps.channel_integration.enable_all = true
    else state.nps.channel_integration.enable_all = false
  },
  UPDATE_CES_CHANNEL_LIST(state, payload) {
    for (let i = 0; i < state.cesChannelLists.length; i++) {
      const group = state.cesChannelLists[i]
      if (group.channel_group === payload?.channel_group) {
        for (let idx = 0; idx < group.channel.length; idx++) {
          const channel = group.channel[idx]
          if (channel.id === payload.channel.id) {
            channel.enable = payload.channel.enabled
          }
        }
      }
    }
  },
  UPDATE_CHANNELS_INT_ID(state, payload) {
    const type = payload?.type
    const ids = []
    state[type].channel_integration.channels.forEach((channel) => {
      ids.push(channel.id)
    })
    state[type].channel_integration.ids = ids
  },
  ON_REMOVE_CHANNELS_IDX(state, payload) {
    const type = payload?.type
    const idx = payload?.idx
    const channels = state[type].channel_integration.channels
    const idsChannel = state[type].channel_integration?.ids
    channels.splice(idx, 1)

    if (idsChannel?.length) {
      const findId = state[type].channel_integration.ids.findIndex((id) => {
        return id === payload.channel.id
      })
      idsChannel.splice(findId, 1)
    }
  },
}
